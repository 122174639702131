import App from '../app';

/**
 * @class
 * @param {object} translations
 * @param {boolean} logTranslations
 * @constructor
 */
export default class Translator {
  constructor(translations, logTranslations) {
    this.translations = translations || {};
    this.logTranslations = logTranslations;
    this.log = {};
  }

  /**
   * This adds to an array a running list of translation names that has gone through this class to be used
   * @param {string} name
   */
  addToLog(name) {
    if (this.logTranslations) {
      if (!this.log[name]) {
        this.log[name] = this.translations[name];
      }
    }
  }

  /**
   * @param {String} name Translation name
   * @param {Object} [params] Values to be parameterized
   * @param {String=} htmlWhitelist list or pack of allowed html tags
   * @returns {String}
   */
  translate(name, params, htmlWhitelist) {
    if (App.platformInfo && App.platformInfo.modifyContentMode) {
      return name;
    }

    const translation = this.translations[name];
    // only if the translation doesn't exist, display the translation name
    // otherwise use what is returned from the server (which honors "invisible")
    if (typeof translation === 'undefined') {
      return name;
    }

    this.addToLog(name);

    if (translation === null) {
      return '';
    }

    const parameterizedTranslation = Translator.parameterize(
      translation,
      params,
    );
    return Translator.sanitizeHtml(parameterizedTranslation, htmlWhitelist);
  }

  getTranslationPrefix(name) {
    if (App.platformInfo && App.platformInfo.modifyContentMode) {
      return name;
    }

    this.addToLog(name);

    const translation = this.translations[name];
    return translation.split('#')[0];
  }
  /**
   * I don't see this used anywhere
   * @param {Number} value The amount
   * @param {String} name Translation name
   * @param {String=} htmlWhitelist list or pack of allowed html tags
   * @returns {String}
   */
  // pluralize(value, name, htmlWhitelist) {
  //   const translation = this.translate(name, null);
  //   if (translation === name) {
  //     return `${translation}(${value})`;
  //   }
  //   const pluralizedTranslation = Translator.pluralize(value, translation);
  //   return Translator.sanitizeHtml(pluralizedTranslation, htmlWhitelist);
  // }

  /**
   * @returns {Array.<string>}
   */
  getLog() {
    return this.log;
  }

  /**
   * @param {String} translation
   * @param {Object} [params]
   */
  static parameterize(translation, params) {
    let trans = translation;
    if (!params || Object.entries(params).length === 0) {
      return trans;
    }

    Object.entries(params).forEach(([key, value]) => {
      trans = trans.replace(
        new RegExp(`#${key}#`, 'g'),
        value,
      );
    });

    return translation;
  }

  /**
   * @param {Number} value
   * @param {String} translationForms
   */
  static pluralize(value, translationForms) {
    if (
      value == null
        || Number.isNaN(value)
        || translationForms == null
        || translationForms.trim() === ''
    ) {
      return translationForms;
    }
    const escaped = translationForms.replace('\\|', '__pipe__');
    // Omit empty strings and trim results
    const forms = escaped
      .split('|') //
      .filter((form) => form && form.trim() !== '') //
      .map((form) => form && form.trim());
    const index = Math.abs(value) < forms.length ? Math.abs(value) : forms.length - 1;
    let translation = forms[index];
    translation = translation.replace('__pipe__', '|');
    return Translator.parameterize(translation, { value });
  }

  /* global SanitizeHtml */
  /* eslint no-undef: "error" */
  static sanitizeHtml(translation, htmlWhitelist) {
    let trans = translation;
    if (trans !== '' && htmlWhitelist && SanitizeHtml) {
      trans = SanitizeHtml(trans, htmlWhitelist);
    }
    return trans;
  }
}
