import MerchandiseSegment from './merchandise_segment';

export default class MerchandiseSegmentInCart extends MerchandiseSegment {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {number} */
    this.quantity = o.quantity === undefined ? 0 : o.quantity;
  }

  /**
     * @param {number} more
     */
  addQuantity(more) {
    const newQuantity = this.quantity + more;
    if (newQuantity <= 99) {
      this.quantity = newQuantity;
    }
  }
}
