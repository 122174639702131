import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import App from '../app';

/**
 * Translates a string through the Translator class
 *
 * @param {array} params; [0] {string} Translation name
 * @param {object} hash
 *        {object} hash.htmlWhitelist
 */
export default helper((params, hash = {}) => {
  const translationName = params[0] || '';
  const translationParams = hash;
  const htmlWhitelist = hash.htmlWhitelist || null;

  if (htmlWhitelist) {
    delete translationParams.htmlWhitelist;
  }

  const translation = App.translator.translate(
    translationName,
    translationParams,
    htmlWhitelist,
  );
  return htmlWhitelist ? htmlSafe(translation) : translation;
});

// let get = Ember.Handlebars.get || Ember.Handlebars.getPath || Ember.getPath,
//     uniqueElementId,
//     Handlebars = Handlebars || Ember.Handlebars;
//
// uniqueElementId = (function () {
//     let id = 0;
//     return function () {
//         let elementId = 'translation-' + id++;
//         return elementId;
//     };
// })();
// /**
//  * Translations Helper v2
//  *
//  * Usage:
//  *
//  * Static translation (You don't need to use the attribute `key` anymore):
//  *
//  *      {{t "TRANSLATION_KEY"}}
//  *
//  * Parameterized translation:
//  *
//  *      {{t "TRANSLATION_KEY" param1="value1" param2="value2"}}
//  *
//  * You can set the context with the target attribute:
//  *
//  *      {{t "TRANSLATION_KEY" target=context param1="name"}}
//  *
//  * Also you can bind parameters:
//  *
//  *      {{t "TRANSLATION_KEY" param1Binding="model.value1"}}
//  *
//  * @param key       The key from which to lookup the translation
//  * @param options
//  * @return          The translation wrapped by a <span> html element
//  */
// App.THelper = Ember.Helper.helper(function (key, options) {
//     Ember.assert("You did not pass translation key to the translation helper", key);
//
// let attrs, context, data, elementID, result, tagName, view, isBinding, htmlWhitelist;
//
// context = options.hash.target || this;
// attrs = options.hash;
// data = options.data;
// view = data.view;
// tagName = attrs.tagName || 'span';
// delete attrs.tagName;
// elementID = uniqueElementId();
// htmlWhitelist = attrs.htmlWhitelist;
//
// Ember.keys(attrs).forEach(function (property) {
//     let bindPath, currentValue, invoker, isBindingMatch, normalized, normalizedPath, observer, propertyName, root, _ref;
//
//     // TODO-dgeb Unquoted bindings should work too
//     isBindingMatch = property.match(/(.+)Binding$/);
//
//     if (isBindingMatch) {
//         isBinding = true;
//         propertyName = isBindingMatch[1];
//         bindPath = attrs[property];
//         currentValue = get(context, bindPath, options);
//         attrs[propertyName] = currentValue;
//         invoker = null;
//         normalized = Ember.Handlebars.normalizePath(context, bindPath, data);
//         _ref = [normalized.root, normalized.path];
//         root = _ref[0];
//         normalizedPath = _ref[1];
//
//         observer = function () {
//             let elem, newValue;
//             let viewState = (typeof view.get('_state') !== 'undefined') ? view.get('_state') : view.get('state'); // "state" deprecated in Ember 1.7
//             if (viewState !== 'inDOM') {
//                 Ember.removeObserver(root, normalizedPath, invoker);
//                 return;
//             }
//             newValue = get(context, bindPath, options);
//             elem = view.$("#" + elementID);
//             attrs[propertyName] = newValue;
//             return elem.html(App.translator.translate(key, attrs, htmlWhitelist));
//         };
//
//         invoker = function () {
//             return Ember.run.once(observer);
//         };
//
//         return Ember.addObserver(root, normalizedPath, invoker);
//     }
// });
//
// result = App.translator.translate(key, attrs, htmlWhitelist);
//
// if (isBinding) {
//     result = '<%@ id="%@">%@</%@>'.fmt(tagName, elementID, result, tagName);
//     // TODO - pshah - this return should be safely using SafeString as we do not want to respect HTML inside translations
//     return new Handlebars.SafeString(result ? result : '');
// }
//
// if(htmlWhitelist) {
//     return new Handlebars.SafeString(result ? result : '');
// }
// return result ? result : '';
// });
