import Service from '@ember/service';
import App from '../app';

export default Service.extend({
  // this property was added because was needed during the Ember.js upgrade TODO: Do we really need this?
  isServiceFactory: true,
  /**
     * Create a cookie
     * @param name {String} Cookie name
     * @param value {String|Object} Cookie value
     * @param [hours] {Object} Duration in hours. Defaults to the duration of the session
     */
  create: App.cookies.create,

  /**
     * Read a cookie
     * @param name {string} Cookie name
     * @return {string|object} Cookie value
     */
  read: App.cookies.read,

  /**
     * Erase a cookie
     * @param name {string} Cookie name
     */
  erase: App.cookies.erase,

  /**
     * Erase all cookies
     */
  eraseAll: App.cookies.eraseAll,
});
