import MerchandiseSegment from './merchandise_segment';

export default class PointAllocation extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {string} */
    this.currencyCode = o.currencyCode === undefined ? null : o.currencyCode;

    /** @type {number} */
    this.cashTotal = o.cashTotal === undefined ? null : o.cashTotal;

    /** @type {number} */
    this.minPointsToDiscount = o.minPointsToDiscount === undefined ? null : o.minPointsToDiscount;

    /** @type {number} */
    this.discountPointsValue = o.discountPointsValue === undefined ? null : o.discountPointsValue;

    /** @type {number} */
    this.discountLeftoverCash = o.discountLeftoverCash === undefined
      ? null
      : o.discountLeftoverCash;

    /** @type {number} */
    this.pointsTotal = o.pointsTotal === undefined ? null : o.pointsTotal;

    /** @type {number} */
    this.shortfallNotRoundable = o.shortfallNotRoundable === undefined
      ? null
      : o.shortfallNotRoundable;

    /** @type {number} */
    this.shortfallRoundable = o.shortfallRoundable === undefined ? null : o.shortfallRoundable;

    // ----- deserialized properties -----

    /** @type {Array.<MerchandiseSegment>} */
    this.deserializeProperty(o, 'merchandiseSegment', MerchandiseSegment);
  }
}
