export default class LoyaltyProfile extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {string} */
    this.userName = o.userName === undefined ? null : o.userName;

    /** @type {string} */
    this.firstName = o.firstName === undefined ? null : o.firstName;

    /** @type {string} */
    this.lastName = o.lastName === undefined ? null : o.lastName;

    /** @type {string} */
    this.middleName = o.middleName === undefined ? null : o.middleName;

    /** @type {string} */
    this.objectKey = o.objectKey === undefined ? null : o.objectKey;

    /** @type {number} */
    this.availablePoints = o.availablePoints === undefined ? null : o.availablePoints;
  }
}
