import MerchandiseSegmentInCart from './merchandise_segment_in_cart';
import PointAllocation from './point_allocation';

export default class MerchandiseShoppingCart extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {boolean} */
    this.invalidState = o.invalidState === undefined ? null : o.invalidState;

    // ----- deserialized properties -----

    this.deserializeArray(
      o,
      'merchandiseSegments',
      MerchandiseSegmentInCart,
    );
    this.deserializeArray(o, 'pointAllocations', PointAllocation);

    // ----- computed properties ---

    /** @type {string} */
    this.setComputedProperty('disableCheckout', this.getDisableCheckout());
  }

  /**
     * @param {MerchandiseSegmentInCart} merchandiseSegment
     * @param {number} quantity
     * @return {MerchandiseShoppingCart}
     */
  addMerchandiseSegment(merchandiseSegment, quantity) {
    let segmentInCart = this.findMerchandiseSegment(merchandiseSegment);
    if (segmentInCart) {
      segmentInCart.addQuantity(quantity);
    } else {
      const properties = merchandiseSegment.getCloneableProperties();
      properties.quantity = quantity;
      segmentInCart = new MerchandiseSegmentInCart(properties);
      this.merchandiseSegments.push(segmentInCart);
    }
    return this;
  }

  /**
     * @param {MerchandiseSegmentInCart} merchandiseSegment
     * @return {MerchandiseShoppingCart}
     */
  deleteMerchandiseSegment(merchandiseSegment) {
    const segmentInCart = this.findMerchandiseSegment(merchandiseSegment);
    if (segmentInCart) {
      for (let i = this.merchandiseSegments.length - 1; i >= 0; i--) {
        const segment = this.merchandiseSegments[i];
        if (segment.merchandiseCode === segmentInCart.merchandiseCode) {
          this.merchandiseSegments.splice(i, 1);
          break;
        }
      }
    }
    return this;
  }

  /**
     * @param {MerchandiseSegmentInCart} merchandiseSegment
     * @param {number} quantity
     * @return {MerchandiseShoppingCart}
     */
  setMerchandiseSegmentQuantity(merchandiseSegment, quantity) {
    const segmentInCart = this.findMerchandiseSegment(merchandiseSegment);
    if (segmentInCart && quantity > 0) {
      segmentInCart.quantity = quantity;
    }
    return this;
  }

  /**
     * @param {MerchandiseSegmentInCart} merchandiseSegment
     * @return {MerchandiseSegmentInCart}
     */
  findMerchandiseSegment(merchandiseSegment) {
    for (let i = this.merchandiseSegments.length - 1; i >= 0; i -= 1) {
      if (
        this.merchandiseSegments[i].merchandiseCode
                === merchandiseSegment.merchandiseCode
      ) {
        return this.merchandiseSegments[i];
      }
    }
    return null;
  }

  /**
     * @param {MerchandiseSegmentInCart} merchandiseSegment
     * @return {number}
     */
  quantityOfMerchandiseSegment(merchandiseSegment) {
    const segmentInCart = this.findMerchandiseSegment(merchandiseSegment);
    return segmentInCart ? segmentInCart.quantity : 0;
  }

  /**
     * return {boolean}
     */
  getDisableCheckout() {
    if (this.invalidState) {
      return true;
    }
    const quantityOfItems = this.merchandiseSegments.length;
    return quantityOfItems < 1;
  }
}
