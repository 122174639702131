import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // passedIn
  disableCheckout: null,
  merchandiseSegments: null,

  isDisabled: computed('merchandiseSegments', function computedMerchandiseSegments() {
    return this.get('merchandiseSegments.length') < 1;
  }),
});
