import Service, { inject } from '@ember/service';
import { later } from '@ember/runloop';
import Ember from 'ember';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import $ from 'jquery';
import App from '../app';

// TODO: This is a Timeout service version suited for ember 1.10 please go to ./timeout_service.js 1.9.1 version

const SESSION_EXPIRATION_COOKIE_NAME = 'EZ_SESSION_EXPIRATION_WARNING';

function serializeDate(sessionExpirationDate) {
  if (sessionExpirationDate.toUTCString) {
    return sessionExpirationDate.toUTCString();
  } if (sessionExpirationDate.toDate) {
    return sessionExpirationDate.toDate().toUTCString();
  }
  return new Date().toUTCString();
}

export default Service.extend({
  cookie: inject(),

  cookieService: alias('cookie'),

  configurations: computed(() => App.configurations),

  sessionExpirationTime: computed({
    get() {
      const cookieService = this.get('cookieService');
      let sessionExpiration = moment(
        new Date(cookieService.read(SESSION_EXPIRATION_COOKIE_NAME)),
      );
      if (!sessionExpiration || !sessionExpiration.isValid()) {
        sessionExpiration = moment();
        cookieService.create(
          SESSION_EXPIRATION_COOKIE_NAME,
          serializeDate(sessionExpiration),
        );
      }
      return sessionExpiration;
    },

    set(keyName, newValue) {
      const cookieService = this.get('cookieService');
      const sessionExpiration = serializeDate(newValue);

      cookieService.create(
        SESSION_EXPIRATION_COOKIE_NAME,
        sessionExpiration,
      );

      return moment(new Date(sessionExpiration));
    },
  }),

  sessionTimeoutInMinutes: computed(function sessionTimeoutInMinutes() {
    return this.get('configurations.sessionTimeoutInMinutes') || 15;
  }),

  sessionTimeoutWarningInMinutes: computed(function sessionTimeoutWarningInMinutes() {
    return this.get('configurations.sessionTimeoutWarningInMinutes') || 0;
  }),

  minutesBeforeTimeout: computed('sessionTimeoutInMinutes', {
    get() {
      return this.get('sessionTimeoutInMinutes');
    },
    set(key, value) {
      this._minutesBeforeTimeout = value;
    },

  }),

  extendSessionUrl: computed(() => `${App.platformInfo.appPath}/extend`),

  isSessionTimingOut: false,

  isSessionTimedOut: false,

  init(initFlag) {
    this._super();

    if (Ember.testing) {
      return;
    }

    if (initFlag) {
      this.resetSessionExpirationTime();

      const self = this;
      // renew the local session expiration on each successful ajax response
      $(document).ajaxComplete((event, xhr, settings) => {
        // extendSession is a custom parameter you can pass in through a jQuery ajax call
        // in order to disable renewing the local session for the specific ajax call. If extendSession
        // is not set, or is set to anything other than "false", the session will be extended
        if (settings.extendSession !== false) {
          self.resetSessionExpirationTime();
        }
      });
    }
  },

  resetSessionExpirationTime() {
    const updatedSessionExpirationTime = moment().add(
      this.get('sessionTimeoutInMinutes'),
      'minutes',
    );

    this.endSessionTimer();
    this.set('sessionExpirationTime', updatedSessionExpirationTime);
    this.beginSessionTimer();
  },

  beginSessionTimer() {
    this.sessionManager();
    this._timer = setInterval(() => {
      this.sessionManager();
    }, 15000);
  },

  endSessionTimer() {
    clearInterval(this._timer);
  },

  sessionManager() {
    const timeNow = moment();
    const sessionExpirationTime = this.get('sessionExpirationTime');
    const sessionTimeoutWarningInMinutes = this.get(
      'sessionTimeoutWarningInMinutes',
    );
    let minutesBeforeTimeout = sessionExpirationTime.diff(
      timeNow,
      'minutes',
      true,
    ); // returns float
    let minutesBeforeTimeoutRoundedForDisplay = Math.round(minutesBeforeTimeout);
    const isSessionExpired = timeNow.isAfter(sessionExpirationTime);

    // negative minutes can occur if window receives focus after the timeout has expired, and sessionManager interval has not stopped
    if (minutesBeforeTimeout < 0) {
      minutesBeforeTimeout = 0;
      minutesBeforeTimeoutRoundedForDisplay = 0;
    }

    // this is to prevent any display of 0-minute remaining until the session is really expired
    if (minutesBeforeTimeoutRoundedForDisplay === 0 && !isSessionExpired) {
      minutesBeforeTimeoutRoundedForDisplay = 1;
    }

    // session timeout warning
    if (
      !this.get('isSessionTimingOut')
            && sessionTimeoutWarningInMinutes > 0
            && minutesBeforeTimeout > 0
            && minutesBeforeTimeout <= sessionTimeoutWarningInMinutes
    ) {
      this.set('isSessionTimingOut', true);
      this.showDialog();
    }

    // session timeout
    if (isSessionExpired) {
      this.endSessionTimer();
      this.set('isSessionTimedOut', true);
      this.set('isSessionTimingOut', false);
      this.showDialog();
    }

    // property used for display
    this.set('minutesBeforeTimeout', minutesBeforeTimeoutRoundedForDisplay);
  },

  showDialog() {
    $('#session-timeout-modal').modal({ keyboard: false });
  },

  hideDialog() {
    $('#session-timeout-modal').modal('hide');
  },

  extendSession() {
    const url = this.get('extendSessionUrl');

    $.ajax({
      url,
      type: 'get',
      dataType: 'json',
      contentType: 'application/json',
      success() {
        this.resetSessionExpirationTime();
        this.hideDialog();

        // After hiding the modal we'll wait a bit (150 ms) before we set `isSessionTimingOut` to false
        // otherwise we'll see the modal body change for a very brief moment before the modal finally
        // closes. 150 ms is the time it takes for the fading animation to finish as per the styling of
        // the "fade" HTML class (at "component-animations").
        // If this number changes we should also update it here for the fix to continue working.
        later(
          this,
          () => {
            this.set('isSessionTimingOut', false);
          },
          150,
        );
      },
      error() {
        // eslint-disable-next-line no-console
        console.log('Failed to extend session timeout');
      },
    });
  },

  willDestroy() {
    this.endSessionTimer();
  },
});
