import Component from '@ember/component';
import { computed } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import App from '../../../app';

export default Component.extend({
  // passedIn
  /** @param {LoyaltyProfile} loyaltyProfile */
  loyaltyProfile: null,

  didInsertElement() {
    scheduleOnce('afterRender', this, () => {
      // apply shim for background size
      $(this.element).find('.logo').css('background-size', 'contain');
      $(this.element).find('.alt-logo').css('background-size', 'contain');
      $(this.element).find('.card-logo').css('background-size', 'contain');
    });
  },

  configurations: computed(() => App.configurations),

  actions: {
    logout() {
      if (this.get('loyaltyProfile')) {
        window.location = '/apps/merchandise/logout';
      }
    },

    login() {
      window.location = `/merchandise/merchandise_sso_redirect.cfm?returnTo=${
        window.location.href.replace('#', '%23')}`;
    },
  },
});
