import Route from '@ember/routing/route';
import config from '../../config/environment';
import {inject as service} from '@ember/service';
import { action } from '@ember/object';

export default class MerchandiseShoppingCartRoute extends Route {
    @service router;

    @action
    returnToShopping() {
        this.router.transitionTo(
            'merchandise_categories.merchandise_category',
            config.DEFAULT_CATEGORY_ID,
        );
    }
}