import Route from '@ember/routing/route';
import config from '../../config/environment';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('merchandise_categories.merchandise_category', config.DEFAULT_CATEGORY_ID);
  }
}