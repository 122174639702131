import { createChangeList } from '../utils/utils';
import MerchandiseShoppingCart from '../models/merchandise_shopping_cart';
import FlashNotice from '../models/flash_notice';

class Main extends LIQUID.stores.SessionStorageStore {
  /**
     * @return {LoyaltyProfile}
     */
  getLoyaltyProfile() {
    return this.getFirstObject(Main.tables.LOYALTY_PROFILES);
  }

  /**
     * @param {LoyaltyProfile} loyaltyProfile
     */
  setLoyaltyProfile(loyaltyProfile) {
    this.clearCollection(Main.tables.LOYALTY_PROFILES);
    this.create(Main.tables.LOYALTY_PROFILES, loyaltyProfile);
    this.fire(Main.events.LOYALTY_PROFILE_CHANGE);
  }

  clearLoyaltyProfile() {
    this.clearCollection(Main.tables.LOYALTY_PROFILES);
  }

  /**
     * @return {Array.<MerchandiseCategory>}
     */
  getCategories() {
    return this.all(Main.tables.MERCHANDISE_CATEGORIES);
  }

  /**
     * @param {Array.<MerchandiseCategory>} categories
     */
  setCategories(categories) {
    this.setCollection(Main.tables.MERCHANDISE_CATEGORIES, categories);
    this.fire(Main.events.CATEGORIES_CHANGE);
  }

  /**
     * @return {MerchandiseCategory}
     */
  getSelectedCategory() {
    return this.getFirstObject(Main.tables.SELECTED_CATEGORY_DETAILS);
  }

  /**
     * @param {MerchandiseCategory} category
     */
  setSelectedCategory(category) {
    this.clearCollection(Main.tables.SELECTED_CATEGORY_DETAILS);
    this.create(Main.tables.SELECTED_CATEGORY_DETAILS, category);
    this.fire(Main.events.SELECTED_CATEGORY_CHANGE);
  }

  /**
     * @return {MerchandiseShoppingCart}
     */
  getShoppingCart() {
    const table = Main.tables.MERCHANDISE_SHOPPING_CARTS;
    const shoppingCart = this.getFirstObject(table);
    if (!shoppingCart) {
      this.create(table, new MerchandiseShoppingCart());
    }
    return this.getFirstObject(Main.tables.MERCHANDISE_SHOPPING_CARTS);
  }

  /**
     * @param {MerchandiseShoppingCart} shoppingCart
     */
  setShoppingCart(shoppingCart) {
    const table = Main.tables.MERCHANDISE_SHOPPING_CARTS;
    const before = this.getShoppingCart();
    this.clearCollection(table);
    this.create(table, shoppingCart);
    const after = this.getShoppingCart();
    const changeList = createChangeList(
      before.serialize(),
      after.serialize(),
      after,
    );

    this.fire(Main.events.SHOPPING_CART_CHANGE, changeList);
  }

  /**
     * @return {FlashNotice}
     */
  getFlashNotice() {
    const table = Main.tables.FLASH_NOTICES;
    const flashNotice = this.getFirstObject(table);
    if (!flashNotice) {
      this.create(table, new FlashNotice());
    }
    return this.getFirstObject(table);
  }

  /**
     * @param {FlashNotice} flashNotice
     */
  setFlashNotice(flashNotice) {
    const table = Main.tables.FLASH_NOTICES;
    const before = this.getFlashNotice();
    this.clearCollection(table);
    this.create(table, flashNotice);
    const after = this.getFlashNotice();
    const changeList = createChangeList(
      before.serialize(),
      after.serialize(),
      after,
    );

    this.fire(Main.events.FLASH_NOTICE_CHANGE, changeList);
  }

  // ----------------- States -----------------

  // todo: not implemented yet
  /**
     * @return {MerchandiseShoppingCartState}
     */
  // App.stores.Main.prototype.getMerchandiseShoppingCartState = function () {
  // let state = this.getFirstObject(Main.tables.MERCHANDISE_SHOPPING_CART_STATES);
  // if (!state) {
  // state = this.create(Main.tables.MERCHANDISE_SHOPPING_CART_STATES, new App.models.MerchandiseShoppingCartState());
  // }
  // return state;
  // };

  /**
     * @param {App.models.MerchandiseShoppingCartState} merchandiseShoppingCartState
     */
  // App.stores.Main.prototype.updateMerchandiseShoppingCartState = function (merchandiseShoppingCartState) {
  // let before = this.getMerchandiseShoppingCartState();
  // this.update(Main.tables.MERCHANDISE_SHOPPING_CART_STATES, merchandiseShoppingCartState);
  // let after = this.getMerchandiseShoppingCartState();
  // let changeList = LIQUID.stores.ObjectStore.createChangeList(before.serialize(), after.serialize(), after);

  // this.fire(Main.events.MERCHANDISE_SHOPPING_CART_STATE_CHANGE, changeList);
  // };
}

/**
 * @public
 * @readonly
 * @enum {string}
 */
Main.events = {
  CATEGORIES_CHANGE: '1',
  SELECTED_CATEGORY_CHANGE: '2',
  LOYALTY_PROFILE_CHANGE: '3',
  SHOPPING_CART_CHANGE: '4',
  MERCHANDISE_SHOPPING_CART_STATE_CHANGE: '5',
  FLASH_NOTICE_CHANGE: '6',
};

/**
 * @protected
 * @readonly
 * @enum {string}
 */
Main.tables = {
  LOYALTY_PROFILES: 'loyaltyProfiles',
  MERCHANDISE_CATEGORIES: 'merchandiseCategories',
  SELECTED_CATEGORY_DETAILS: 'selectedCategoryDetails',
  MERCHANDISE_SHOPPING_CARTS: 'merchandiseShoppingCarts',
  POINT_ALLOCATIONS: 'pointAllocations',
  MERCHANDISE_SHOPPING_CART_STATES: 'merchandiseShoppingCartState',
  FLASH_NOTICES: 'flashNotices',
};

export default Main;
