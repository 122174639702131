import Controller from '@ember/controller';
import { inject } from '@ember/service';
import ChangeListMixin from '../../mixins/change_list';
import App from '../../app';
import Main from '../../liquid/stores/main';

export default Controller.extend(ChangeListMixin, {
  spinner: inject(),

  timeout: inject(),

  /**
     * @type {Array.<MerchandiseCategory>}
     */
  model: null,

  /**
     * @type {MerchandiseCategory}
     */
  selectedCategory: null,

  /**
     * @type {MerchandiseShoppingCart}
     */
  shoppingCart: null,

  /**
     * @param {LoyaltyProfile} loyaltyProfile
     */
  loyaltyProfile: null,

  init() {
    this._super();

    const store = App.mainStore;

    store.subscribeAndInit(
      Main.events.CATEGORIES_CHANGE,
      this,
      () => {
        this.set('model', store.getCategories());
      },
    );

    store.subscribeAndInit(
      Main.events.SELECTED_CATEGORY_CHANGE,
      this,
      () => {
        this.set('selectedCategory', store.getSelectedCategory());
      },
    );

    this.set('shoppingCart', store.getShoppingCart());
    store.subscribe(
      Main.events.SHOPPING_CART_CHANGE,
      this,
      (changeList) => {
        this.applyChangeList('shoppingCart', changeList);
      },
    );

    store.subscribeAndInit(
      Main.events.LOYALTY_PROFILE_CHANGE,
      this,
      () => {
        this.set('loyaltyProfile', store.getLoyaltyProfile());
      },
    );
  },
});
