import App from '../../app';
import MerchandiseShoppingCart from '../models/merchandise_shopping_cart';
import LoyaltyProfile from '../models/loyalty_profile';
import MerchandiseCategory from '../models/merchandise_category';

class Main extends LIQUID.actions.Base {
  /**
     * @param {object} pageData
     */
  apiInitializePageData(pageData) {
    return this.initializeShoppingCart().then(() => {
      if (pageData.loyaltyProfiles) {
        App.mainStore.setLoyaltyProfile(
          new LoyaltyProfile(pageData.loyaltyProfiles[0]),
        );
      } else {
        App.mainStore.clearLoyaltyProfile();
      }
    });
  }

  /**
     * @private
     * @return {Promise}
     */
  initializeShoppingCart() {
    return this.get(Main.requests.GET_MERCHANDISE_SHOPPING_CART).then(
      (response) => {
        const shoppingCart = new MerchandiseShoppingCart(
          response.merchandiseShoppingCart,
        );
        App.mainStore.setShoppingCart(shoppingCart);
      },
    );
  }

  /**
     * @param {MerchandiseSegment} segment
     * @param {number} quantity
     * @return {Promise}
     */
  apiAddSegmentToShoppingCart(segment, quantity) {
    const current = App.mainStore.getShoppingCart();
    current.addMerchandiseSegment(segment, parseInt(quantity, 10));
    return this.updateShoppingCart(current);
  }

  /**
     * @param {MerchandiseSegment} segment
     * @return {Promise}
     */
  apiRemoveSegmentFromShoppingCart(segment) {
    const current = App.mainStore.getShoppingCart();
    current.deleteMerchandiseSegment(segment);
    return this.updateShoppingCart(current);
  }

  /**
     * @param {MerchandiseSegment} segment
     * @param {number} quantity
     * @return {Promise}
     */
  apiUpdateSegmentQuantityInShoppingCart(segment, quantity) {
    const quantityToUpdate = parseInt(quantity, 10);
    if (quantityToUpdate > 0) {
      const current = App.mainStore.getShoppingCart();
      current.setMerchandiseSegmentQuantity(segment, quantity);
      return this.updateShoppingCart(current);
    }
    return this.apiRemoveSegmentFromShoppingCart(segment);
  }

  /**
     * @private
     * @param {MerchandiseShoppingCart} shoppingCart
     * @return {Promise}
     */
  updateShoppingCart(shoppingCart) {
    return this.put(
      Main.requests.GET_MERCHANDISE_SHOPPING_CART,
      this.getUpdateShoppingCartRequest(shoppingCart),
    ).then((response) => {
      App.mainStore.setShoppingCart(new MerchandiseShoppingCart(response.merchandiseShoppingCart));
    });
  }

  /**
     * @private
     * @param {MerchandiseShoppingCart} shoppingCart
     * @return {object}
     */
  getUpdateShoppingCartRequest(shoppingCart) {
    return {
      merchandiseSegments: shoppingCart.merchandiseSegments,
      pointAllocations: shoppingCart.pointsAllocation,
      invalidState: shoppingCart.invalidState,
    };
  }

  /**
     * @return {Promise}
     */
  apiGetCategories() {
    return this.get(Main.requests.GET_MERCHANDISE_CATEGORIES).then(
      (data) => {
        const rawCategories = data.merchandiseCategories || [];
        App.mainStore.setCategories(
          rawCategories.map((category) => new MerchandiseCategory(category)),
        );
      },
    );
  }

  /**
     * @param {number} categoryId
     * @return {Promise}
     */
  apiSelectCategory(categoryId) {
    const categories = App.mainStore.getCategories();
    if (categories.length === 0) {
      return this.apiGetCategories().then(() => this._selectCategory(categoryId));
    }
    return this._selectCategory(categoryId);
  }

  /**
     * @param {number} categoryId
     * @return {Promise}
     */
  _selectCategory(categoryId) {
    return new Promise((resolve) => {
      const categories = App.mainStore.getCategories();
      const filteredCategories = categories.filter((category) => category.id === categoryId);

      const category = filteredCategories && filteredCategories[0];
      if (category) {
        App.mainStore.setSelectedCategory(category);
        resolve(App.mainStore.getSelectedCategory());
      }
    });
  }

  apiDisplayMessage(message, messageType) {
    return new Promise((resolve) => {
      this.apiClearMessages();

      if (message) {
        const type = messageType || 'error';
        this.apiAddMessage(type, message);
        // this.scrollToError();
      }
      resolve();
    });
  }

  apiAddMessages(messages) {
    return new Promise((resolve) => {
      if (!messages) {
        return;
      }

      const flashNotice = App.mainStore.getFlashNotice();
      messages.forEach((msg) => {
        const type = msg.messageType.toLowerCase();
        if (flashNotice[type].indexOf(msg.translationName) === -1) {
          flashNotice[type].push(msg.translationName);
        }
      });
      App.mainStore.setFlashNotice(flashNotice);
      resolve();
    });
  }

  /**
     * Add message by type.  Defaults to success if no type is provided.
     * @param {string} type
     * @param {string} message
     */
  apiAddMessage(...args) {
    let [type, message] = args;
    return new Promise((resolve) => {
      const flashNotice = App.mainStore.getFlashNotice();

      if (args.length < 2) {
        message = type;
        type = 'success';
      }

      const collection = flashNotice[type];

      // Don't push duplicate error messages
      if (collection.indexOf(message) === -1) {
        collection.push(message);
      }
      App.mainStore.setFlashNotice(flashNotice);
      resolve();
    });
  }

  /**
     * @param {string} type
     * @param {string} message
     * @return {boolean}
     */
  removeMessage(type, message) {
    return new Promise((resolve) => {
      const flashNotice = App.mainStore.getFlashNotice();
      const collection = flashNotice[type];
      const index = collection.indexOf(message);

      let ret;
      if (index !== -1) {
        collection.splice(index, 1);
        ret = true;
      }

      ret = false;
      App.mainStore.setFlashNotice(flashNotice);
      resolve(ret);
    });
  }

  /**
     * @param {string} type
     */
  apiClearMessages(type) {
    return new Promise((resolve) => {
      const flashNotice = App.mainStore.getFlashNotice();
      if (type) {
        flashNotice[type] = [];
      } else {
        flashNotice.error = [];
        flashNotice.success = [];
        flashNotice.info = [];
        flashNotice.warn = [];
      }
      App.mainStore.setFlashNotice(flashNotice);
      resolve();
    });
  }
}

Main.requests = {
  GET_MERCHANDISE_SHOPPING_CART:
        '/apps/merchandise/merchandiseShoppingCarts/current',
  GET_MERCHANDISE_CATEGORIES: '/apps/merchandise/merchandiseCategories',
};

export default Main;
