import Component from '@ember/component';
import { computed } from '@ember/object';
import App from '../../../app';
import Main from '../../../liquid/actions/main';

export default Component.extend({
  // passedIn
  /**
     * @type {MerchandiseSegment}
     */
  merchandiseSegment: null,

  popoverContent: computed('merchandiseSegment.quantity', () => {}),

  quantityToUpdateError: computed('merchandiseSegment.quantity', function computedQuantityToUpdateError() {
    const rawQuantityToUpdate = this.get('merchandiseSegment.quantity');
    if ((`${rawQuantityToUpdate}`).indexOf('.') !== -1) {
      return App.translator.translate(
        'MERCHANDISE_ADD_ITEMS_LIMIT_MESSAGE',
        {},
      );
    }
    const quantity = parseInt(rawQuantityToUpdate, 10);
    if (
      !quantity
            || !Number.isFinite(quantity)
            || quantity < 1
            || quantity > 99
    ) {
      return App.translator.translate(
        'MERCHANDISE_ADD_ITEMS_LIMIT_MESSAGE',
        {},
      );
    }
    return undefined;
  }),

  isInvalid: computed('quantityToUpdateError', function computedQuantityToUpdateError() {
    return this.get('quantityToUpdateError');
  }),

  handleActionResult(promise) {
    promise
      .then(() => {
        this.successCallback(this);
      })
      .catch(() => {
        // TODO 116828 ?
        // this.failureCallback();
      });
  },

  successCallback() {
    return function successCallback() {
      new Main().apiClearMessages();
    };
  },

  failureCallback(cart) {
    return function failureCallback() {
      cart.get('stateManager').transitionTo('loaded.saved');
      cart.reload();
    };
  },

  subtotal: computed('merchandiseSegment.{quantity,sellPrice}', function computerSubtotal() {
    return (
      parseFloat(this.merchandiseSegment.quantity)
            * this.merchandiseSegment.sellPrice
    )
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }),

  actions: {
    updateShoppingCart() {
      new Main()
        .apiUpdateSegmentQuantityInShoppingCart(
          this.get('merchandiseSegment'),
          this.get('merchandiseSegment.quantity'),
        )
        .catch((error) => {
          let mess = 'Unable to update shopping cart';
          if (error.message) {
            mess += `: ${error.message}`;
          }
          new Main().apiDisplayMessage(mess, 'error');
        });
    },

    removeSegmentFromShoppingCart(merchandiseSegment) {
      if (
      // eslint-disable-next-line no-alert
        !window.confirm(
          App.translator.translate(
            'MERCHANDISE_CONFIRM_ITEM_REMOVE_MESSAGE',
            {},
          ),
        )
      ) {
        return;
      }
      new Main()
        .apiRemoveSegmentFromShoppingCart(merchandiseSegment)
        .catch((error) => {
          let mess = 'Unable to update shopping cart';
          if (error.message) {
            mess += `: ${error.message}`;
          }
          new Main().apiDisplayMessage(mess, 'error');
        });
    },
  },
});
