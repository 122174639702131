import MerchandiseSegment from './merchandise_segment';

export default class MerchandiseCategory extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {number} */
    this.id = o.id === undefined ? null : o.id;

    /** @type {string} */
    this.name = o.name === undefined ? null : o.name;

    /** @type {Array.<string>} */
    this.policies = o.policies || [];

    // ----- deserialized properties -----

    /** @type {Array.<MerchandiseSegment>} */
    this.deserializeArray(o, 'merchandiseSegments', MerchandiseSegment);
  }
}
