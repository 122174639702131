import Controller from '@ember/controller';
import { observer } from '@ember/object';
import $ from 'jquery';
import { sort } from '@ember/object/computed';
import ChangeListMixin from '../../../mixins/change_list';
import App from '../../../app';
import Main from '../../../liquid/stores/main';
import config from '../../../config/environment';

export default Controller.extend(ChangeListMixin, {
  needs: ['merchandiseCategories'],
  numItemsDisplayed: config.DEFAULT_NUM_ITEMS_TO_SHOW,
  sortOptions: null,
  sortOptionsMap: null,
  categorySortMap: {},
  sortSegmentsBy: null,
  sortDefinition: ['defaultSortId:asc'],

  /**
     * @type {MerchandiseCategory}
     */
  selectedCategory: null,

  sortedSegments: sort(
    'selectedCategory.merchandiseSegments',
    'sortDefinition',
  ),

  init() {
    this._super();
    const store = App.mainStore;

    const defaultSort = App.translator.translate(
      'MERCHANDISE_DEFAULT_SORT_NAME',
      {},
    );
    const ascendingPriceSort = App.translator.translate(
      'MERCHANDISE_PRICE_ASCENDING_SORT_NAME',
      {},
    );
    const descendingPriceSort = App.translator.translate(
      'MERCHANDISE_PRICE_DESCENDING_SORT_NAME',
      {},
    );
    const ascendingNameSort = App.translator.translate(
      'MERCHANDISE_NAME_ASCENDING_SORT_NAME',
      {},
    );
    const descendingNameSort = App.translator.translate(
      'MERCHANDISE_NAME_DESCENDING_SORT_NAME',
      {},
    );

    const sortOptionsMap = {};
    sortOptionsMap[defaultSort] = ['defaultSortId:asc'];
    sortOptionsMap[ascendingPriceSort] = ['sellPrice:asc'];
    sortOptionsMap[descendingPriceSort] = ['sellPrice:desc'];
    sortOptionsMap[ascendingNameSort] = ['name:asc'];
    sortOptionsMap[descendingNameSort] = ['name:desc'];

    this.set('sortSegmentsBy', defaultSort);
    this.set('sortOptions', [
      defaultSort,
      ascendingPriceSort,
      descendingPriceSort,
      ascendingNameSort,
      descendingNameSort,
    ]);

    this.set('sortOptionsMap', sortOptionsMap);

    this.set('cart', store.getShoppingCart());
    store.subscribe(
      Main.events.SHOPPING_CART_CHANGE,
      this,
      (changeList) => {
        this.applyChangeList('cart', changeList);
      },
    );

    this.set('flashNotice', store.getFlashNotice());
    store.subscribe(
      Main.events.FLASH_NOTICE_CHANGE,
      this,
      (changeList) => {
        this.applyChangeList('flashNotice', changeList);
      },
    );

    store.subscribeAndInit(
      Main.events.SELECTED_CATEGORY_CHANGE,
      this,
      () => {
        const selectedCategory = store.getSelectedCategory();
        if (selectedCategory) {
          this.set('selectedCategory', selectedCategory);

          const categoryName = selectedCategory.name;
          let selectedCategorySort = this.get('categorySortMap')[categoryName];
          if (!selectedCategorySort) {
            selectedCategorySort = App.translator.translate(
              'MERCHANDISE_DEFAULT_SORT_NAME',
              {},
            );
            this.get('categorySortMap')[categoryName] = selectedCategorySort;
          }

          this.set('sortSegmentsBy', selectedCategorySort);
        }
      },
    );
  },

  selectedOptionChanged: observer('sortSegmentsBy', function observerSortSegmentsBy() {
    const sortOptionsMap = this.get('sortOptionsMap');
    const sortSegmentsBy = this.get('sortSegmentsBy');

    const sortOptions = sortOptionsMap[sortSegmentsBy];
    if (sortOptions) {
      const categoryName = this.get('model.name');
      this.set('sortDefinition', sortOptions);
      this.get('categorySortMap')[categoryName] = sortSegmentsBy;
    }
  }),

  actions: {
    changeCategory() {
      const selectedEl = $('select')[0];
      this.set(
        'sortSegmentsBy',
        this.get('sortOptions')[selectedEl.selectedIndex],
      );
    },
  },
});
