import Component from '@ember/component';
import { computed } from '@ember/object';
import Ember from 'ember';
import App from '../../../app';

const states = ['previous-route', 'current-route', ''];

export default Component.extend({
  router: Ember.inject.service(),

  currentPath: computed(function computedCurrenPath() {
    return this.get('router').currentRouteName;
  }),

  /** @type {Array.<Object>} */
  tabsInfo: computed('currentPath', function computedTabsInfo() {
    const currentPath = this.get('currentPath');
    let currentState = 0;
    return [
      {
        name: App.translator.translate(
          'MERCHANDISE_NAV_BREADCRUMB_SELECT',
          {},
        ),
        routeName: 'merchandise_categories.merchandise_category',
      },
      {
        name: App.translator.translate(
          'MERCHANDISE_NAV_BREADCRUMB_SHOPPING_CART',
          {},
        ),
        routeName: 'merchandise_shopping_cart',
      },
      {
        name: App.translator.translate(
          'MERCHANDISE_NAV_BREADCRUMB_PAYMENT',
          {},
        ),
        routeName: 'payment',
      },
      {
        name: App.translator.translate(
          'MERCHANDISE_NAV_BREADCRUMB_CONFIRMATION',
          {},
        ),
        routeName: 'confirm',
      },
    ].map((item, index) => {
      if (item.routeName === currentPath || currentState === 1) {
        currentState += 1;
      }
      return {
        count: index + 1,
        name: item.name,
        routeName: item.routeName,
        state: states[currentState],
      };
    });
  }),
});
