import Component from '@ember/component';
import { computed } from '@ember/object';
import App from '../../../app';

export default Component.extend({
  timeoutRedirectUrl: computed('App.configurations.defaultUrl', () => App.configurations.defaultUrl),

  actions: {
    redirectToRoot() {
      window.location = this.get('timeoutRedirectUrl') || '/apps/merchandise/';
    },
  },
});
