import Route from '@ember/routing/route';
import config from '../../../config/environment';
import Main from '../../../liquid/actions/main';

export default Route.extend({
  model(params) {
    const merchandiseCategoryId = parseInt(
      params.id || config.DEFAULT_CATEGORY_ID,
      10,
    );
    return new Main().apiSelectCategory(merchandiseCategoryId);
  },

  setupController(controller, model) {
    if (model.id) {
      new Main().apiSelectCategory(model.id);
    }

    // controller.set('model', model);
  },

  actions: {
    error() {
      this.transitionTo(
        'merchandise_categories.merchandise_category',
        config.DEFAULT_CATEGORY_ID,
      );
    },
  },
});
