import Service from '@ember/service';
import $ from 'jquery';
import App from '../app';

export default Service.extend({
  // this property was added because was needed during the Ember.js upgrade TODO: Do we really need this?
  isServiceFactory: true,
  _initialized: false,
  _timeLastOpened: null,
  $node: null,
  minimumDisplayInMilliseconds: 1500,
  isShown: false,

  _initialize() {
    const $container = $(
      `<div id="loading-modal" class="modal fade in automation-loading-dialog" tabindex="-1" data-backdrop="static" role="dialog" aria-hidden="false" style="display: block;"><div class="modal-dialog"><div class="modal-content"><div class="modal-body text-center"><div role="progressbar" aria-valuetext="${
        App.translator.translate('NTS_SPINNER_LABEL')
      }"><p><i class="icon icon-large icon-spin icon-spinner automation-interstitial-ad-spinner"></i> <span class="loading-message"></span></p></div></div></div></div></div>`,
    );
    this.$node = $container;
  },

  show(message) {
    if (!this._initialized) {
      this._initialize();
      this._initialized = true;
    }

    // TODO jlee support html whitelist for the message (TP #99505)
    this.$node.find('.loading-message').html(message);

    this.set('_timeLastOpened', new Date().getTime());
    this.$node.modal();
    this.set('isShown', true);
  },

  /**
     * @return {Promise} Promise resolves when the modal completely closes
     */
  hide() {
    const self = this;

    return new Promise((resolve) => {
      if (self.$node) {
        self.$node.on('hidden.bs.modal', () => {
          $('.modal-backdrop').remove();
          self.set('isShown', false);
          $(document.body).focus();
          resolve();
        });

        const currentTime = new Date().getTime();
        const minimumTime = self.get('minimumDisplayInMilliseconds');
        const delta = currentTime - self.get('_timeLastOpened');

        if (delta < minimumTime) {
          setTimeout(() => {
            self.$node.modal('hide');
          }, Math.abs(minimumTime - delta));
        } else {
          self.$node.modal('hide');
        }
      } else {
        self.set('isShown', false);
        resolve();
      }
    });
  },
});
