import Route from '@ember/routing/route';
import EmberObject from '@ember/object';
import { inject } from '@ember/service';
import App from '../../app';
import Main from '../../liquid/actions/main';
import MainStore from '../../liquid/stores/main';
import LoyaltyProfile from '../../liquid/models/loyalty_profile';
import FlashNotice from '../../liquid/models/flash_notice';
import MerchandiseCategory from '../../liquid/models/merchandise_category';
import MerchandiseShoppingCart from '../../liquid/models/merchandise_shopping_cart';
import PointAllocation from '../../liquid/models/point_allocation';
import MerchandiseShoppingCartState from '../../liquid/models/merchandise_shopping_cart_state';

export default Route.extend({
  timeout: inject(),

  beforeModel() {
    this.initializePageData();
    this.get('timeout').init(true);
  },

  initializePageData() {
    const pageData = window.PAGE_DATA;
    const {
      translations: [translations], platforms: [platforms], configurations, messages,
    } = pageData;

    App.translator.translations = translations;
    App.platformInfo = platforms;
    App.configurations = EmberObject.create(configurations[0]);

    // init liquid
    LIQUID.init({
      defaultErrorMessage: App.translator.translate(
        'NTS_ERROR_DEFAULT_ALERT',
        {},
      ),
      promiseImpl: Promise,
      serverLogging: false,
      csrfToken: App.platformInfo.csrfToken,
    });

    // domain stores
    App.mainStore = new MainStore({
      sessionStorageKey: 'store:main',
      sessionStorage: window.sessionStorage,
      schema: {
        loyaltyProfiles: LoyaltyProfile,
        merchandiseCategories: MerchandiseCategory,
        selectedCategoryDetails: MerchandiseCategory,
        merchandiseShoppingCarts: MerchandiseShoppingCart,
        pointAllocations: PointAllocation,
        merchandiseShoppingCartState: MerchandiseShoppingCartState,
        flashNotices: FlashNotice,
      },
    });

    new Main().apiAddMessages(messages);
    new Main().apiInitializePageData(pageData).catch((error) => {
      // eslint-disable-next-line no-unused-vars
      let mess = 'Unable to load shopping cart';
      if (error.message) {
        mess += `: ${error.message}`;
      }
      new Main().apiDisplayMessage(mess, 'error');
    });
  },

  actions: {
    login() {
      window.location = `/merchandise/merchandise_sso_redirect.cfm?returnTo=${
        window.location.href.replace('#', '%23')}`;
    },
  },
});
