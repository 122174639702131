import Controller from '@ember/controller';
import ChangeListMixin from '../../mixins/change_list';
import App from '../../app';
import Main from '../../liquid/stores/main';

export default Controller.extend(ChangeListMixin, {
  /**
     * @type {MerchandiseShoppingCart}
     */
  merchandiseShoppingCart: null,

  /**
     * @param {LoyaltyProfile} loyaltyProfile
     */
  loyaltyProfile: null,

  /**
     * @type {number}
     */
  selectedValue: null,

  /**
     * @type {string}
     */
  currencySymbol: null,

  /**
     * @type {string}
     */
  csrfToken: null,

  init() {
    this._super();

    const store = App.mainStore;

    this.set('merchandiseShoppingCart', store.getShoppingCart());
    store.subscribe(
      Main.events.SHOPPING_CART_CHANGE,
      this,
      (changeList) => {
        this.applyChangeList('merchandiseShoppingCart', changeList);

        const shoppingCart = store.getShoppingCart();
        // this.set('merchandiseShoppingCart', shoppingCart);
        this.set('csrfToken', App.platformInfo.csrfToken);

        if (shoppingCart.merchandiseSegments[0]) {
          this.set(
            'currencySymbol',
            shoppingCart.merchandiseSegments[0].currencySymbol,
          );
        }
        if (shoppingCart.pointAllocations[0]) {
          this.set(
            'selectedValue',
            shoppingCart.pointAllocations[0].pointsTotal,
          );
        }
      },
    );

    this.set('flashNotice', store.getFlashNotice());
    store.subscribe(
      Main.events.FLASH_NOTICE_CHANGE,
      this,
      (changeList) => {
        this.applyChangeList('flashNotice', changeList);
      },
    );

    store.subscribeAndInit(
      Main.events.LOYALTY_PROFILE_CHANGE,
      this,
      () => {
        this.set('loyaltyProfile', store.getLoyaltyProfile());
      },
    );
  },

  actions: {
    setSelectedValue(value) {
      this.set('selectedValue', value);
    },
  },
});
