export default class FlashNotice extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {string} */
    this.name = o.name === undefined ? null : o.name;

    /** @type {Array.<string>} */
    this.error = o.error || [];

    /** @type {Array.<string>} */
    this.info = o.info || [];

    /** @type {Array.<string>} */
    this.warn = o.warn || [];

    /** @type {Array.<string>} */
    this.success = o.success || [];

    // ----- deserialized properties -----

    // ----- computed properties ---

    /** @type {boolean} */
    this.setComputedProperty('hasErrorMessages', this.error.length > 0);

    /** @type {boolean} */
    this.setComputedProperty('hasInfoMessages', this.info.length > 0);

    /** @type {boolean} */
    this.setComputedProperty('hasWarnMessages', this.warn.length > 0);

    /** @type {boolean} */
    this.setComputedProperty('hasSuccessMessages', this.success.length > 0);

    /** @type {boolean} */
    this.setComputedProperty(
      'hasMessages',
      this.hasErrorMessages
                || this.hasInfoMessages
                || this.hasWarnMessages
                || this.hasSuccessMessages,
    );
  }
}
