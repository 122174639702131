import EmberRouter from '@ember/routing/router';
import Main from './liquid/actions/main';
import App from './app';

let _shouldClearMessage = false;

const Router = EmberRouter.extend({
  persistMessages(routeName) {
    const _pageSpecificErrors = {
      // to use this, the ember object handlerInfo.name is used as the key
      // and the value is the translation of the error you would like to pass to
      // handlerInfo.name route
      merchandise_shopping_cart: [
        App.translator.translations.NO_REDEMPTION_NOT_ENOUGH_POINTS,
      ],
      merchandise_categories: [
        App.translator.translations.INVALID_MERCHANDISE_SEARCH_CATEGORY,
      ],
    };

    // we don't want to clear the messages at the first time.
    if (_shouldClearMessage) {
      const flashNotice = App.mainStore.getFlashNotice();
      const flashErrors = flashNotice.error;
      const persistedErrors = [];
      let errorTranslations;
      let errorTranslation;

      Object.keys(_pageSpecificErrors).forEach((key) => {
        errorTranslations = _pageSpecificErrors[key];
        for (let i = 0, ii = flashErrors.length; i < ii; i++) {
          errorTranslation = errorTranslations[i];
          if (
            flashErrors[i] === errorTranslation
              && routeName.includes(key)
          ) {
            persistedErrors.push(errorTranslation);
          }
        }
      });

      new Main().apiClearMessages().then(() => {
        for (let i = 0, ii = persistedErrors.length; i < ii; i++) {
          new Main().apiAddMessage('error', persistedErrors[i]);
        }
      });
    }

    _shouldClearMessage = true;
  },
  init(...initArgs) {
    this.on('routeDidChange', (transition) => {
      this.persistMessages(transition.to.name);
    });
    // this method is an after hook for the TransitionTo method.
    // it is responsible to clear or persist error messages between routes

    this._super.apply(this, ...initArgs);
  },
});

// eslint seems to think this is js map but it's an Ember thing
// eslint-disable-next-line array-callback-return
Router.map(function routerMap() {
  // TODO: rename the url routes to be more user friendly?
  this.route(
    'merchandise_categories',
    { path: '/categories' },
    function resourceCallback() {
      this.route('merchandise_category', {
        path: ':id',
      });
    },
  );

  this.route('merchandise_shopping_cart', { path: '/shopping_cart' });
});

export default Router;
