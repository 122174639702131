import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import $ from 'jquery';
import App from '../../../app';
import Main from '../../../liquid/actions/main';

export default Component.extend({
  // passedIn
  cart: null,

  /**
     * @type {Array.<MerchandiseSegment>}
     */
  merchandiseSegment: null,

  shouldShowMore: false,
  quantityToAdd: 1,

  didInsertElement() {
    this.clearItems();
    this._resetPopover();
  },

  popoverContent: computed('quantityInCart', function computedPopoverContent() {
    const quantity = this.get('quantityInCart');
    const quantifier = App.translator.translate(
      'MERCHANDISE_ADD_TO_BASKET_CASE',
    );
    // noinspection HtmlUnknownAnchorTarget
    return (
      `${App.translator.translate(
        'MERCHANDISE_SHOPPING_LIST_ITEMS_MESSAGE_FIRST',
        {},
      )
      } ${
        quantity
      } ${
        quantifier
      }(s)`
            + ` ${
              App.translator.translate(
                'MERCHANDISE_SHOPPING_LIST_ITEMS_MESSAGE_SECOND',
                {},
              )
            }<br/> <a href='#/shopping_cart'>${
              App.translator.translate(
                'MERCHANDISE_SHOPPING_LIST_GO_TO_CART_MESSAGE',
                {},
              )
            }</a>`
    );
  }),

  // Keep the Popover's content in sync with the quantity in the cart
  // NOTE: this may be better handled by moving the popoverContent into
  //       a template which contains a simple binding to quantityInCart
  _resetPopover: observer('quantityInCart', function observerQuantityInCart() {
    if (this.get('_state') === 'inDOM') {
      if (this.get('quantityInCart') > 0) {
        this._hidePopover();
        this._showPopover();
      } else if ($(this.element).popover.length > 0) {
        this._hidePopover();
      }
    }
  }),

  _showPopover() {
    if ($(this.element).popover.length > 0) {
      this._hidePopover();
    }

    // TODO: move this to a mixin component because it's needed on shopping_item.js
    $(this.element).find('button[type=submit]')
      .popover({
        content: this.get('popoverContent'),
        animation: false,
        html: true,
      })
      .popover('show');
  },

  _hidePopover() {
    $(this.element).find('button[type=submit]').popover('destroy');
  },

  setNumItemsDisplayed(initNumItemsToShow) {
    this.onNumItemsDisplayedChange(initNumItemsToShow);
  },

  clearItems() {
    const merchItems = $('.merchandise-segment');
    // fixme: actions shouldn't return anything
    const segmentLength = this.get('sortedSegments').length;
    const initNumItemsToShow = segmentLength;
    const $footer = $('footer');
    const $bottomShoppingCart = $('.shopping-cart-container.bottom');

    if (segmentLength) {
      if (
        merchItems.length > 0
                && $(merchItems[initNumItemsToShow]).css('display') !== 'none'
      ) {
        for (let i = initNumItemsToShow; i < segmentLength; i++) {
          $(merchItems[i]).hide();
        }
      }

      if (
        merchItems.length > 0
                && initNumItemsToShow < merchItems.length
      ) {
        $footer.hide();
        $bottomShoppingCart.hide();
      } else {
        $footer.show();
        $bottomShoppingCart.show();
      }
    }

    // reset infinite scroll and counter
    this.setNumItemsDisplayed(initNumItemsToShow);
    $.scrollup();
  },

  quantityInCart: computed(
    'cart.merchandiseSegments.@each.quantity',
    function computedQuantityInCart() {
      const cart = this.get('cart');
      return cart
        ? cart.quantityOfMerchandiseSegment(
          this.get('merchandiseSegment'),
        )
        : 0;
    },
  ),

  showMoreDescription: computed(function computedShowMoreDescription() {
    return this.get('merchandiseSegment.description.length') > 100;
  }),

  quantityToAddError: computed(
    'quantityToAdd',
    'quantityInCart',
    function computedQuantityToAddError() {
      const currentQuantityInCart = this.get('quantityInCart');
      const quantityToAddString = this.get('quantityToAdd');
      if ((`${quantityToAddString}`).indexOf('.') !== -1) {
        return App.translator.translate(
          'MERCHANDISE_ADD_ITEMS_LIMIT_MESSAGE',
          {},
        );
      }
      const quantityToAdd = parseInt(quantityToAddString, 10);
      if (
        !quantityToAdd
                || !Number.isFinite(quantityToAdd)
                || quantityToAdd < 1
                || quantityToAdd > 99
      ) {
        return App.translator.translate(
          'MERCHANDISE_ADD_ITEMS_LIMIT_MESSAGE',
          {},
        );
      } if (quantityToAdd + currentQuantityInCart > 99) {
        return App.translator.translate(
          'MERCHANDISE_ORDER_LIMIT_MESSAGE',
          {},
        );
      }
      return undefined;
    },
  ),

  isInvalid: computed('quantityToAddError', function computedIsValid() {
    return this.get('quantityToAddError');
  }),

  actions: {
    // any action from merchandise segment template or view should come here
    toggleShowLink() {
      const shouldShowMore = this.get('shouldShowMore');
      this.set('shouldShowMore', !shouldShowMore);
    },

    addToShoppingCart() {
      new Main()
        .apiAddSegmentToShoppingCart(
          this.get('merchandiseSegment'),
          this.get('quantityToAdd'),
        )
        .catch((error) => {
          let mess = 'Unable to update shopping cart';
          if (error.message) {
            mess += `: ${error.message}`;
          }
          new Main().apiDisplayMessage(mess, 'error');
        });
      this.set('quantityToAdd', 1);
    },
  },
});
