import Component from '@ember/component';
import { scheduleOnce } from '@ember/runloop';
import { computed } from '@ember/object';
import $ from 'jquery';
import Main from '../../../liquid/actions/main';

export default Component.extend({
  /** @type {FlashNotice} */
  flashNotice: null,

  // shouldDisplayErrorLink: false,

  didInsertElement() {
    if (this.flashNotice.hasMessages) {
      this.scrollToError();
    }
  },

  scrollToError() {
    scheduleOnce('afterRender', window, function scheduleOnceAfterRender() {
      const $flashError = $('#flash-error');
      const flashElementPosition = $flashError.position(); // TODO: Refactor to use $('.automation-flashError').first() instead; currently only selects ONE flash type.
      if (flashElementPosition) {
        this.scrollTo(0, flashElementPosition.top);
        $flashError.focus();
      }
    });
  },

  // COMPUTED PROPERTIES

  messages: computed('currentMessageType', function computedCurrentMessageType() {
    const currentType = this.get('currentMessageType');
    return currentType ? this.get(currentType) : null;
  }),

  /*
         return messages in order of importance as messages do not stack in the UI.
         the higher priority message must be cleared in order for messages of lesser
         priority to be delivered
         Return string of message type based on which message bucket is current being displayed
         */
  currentMessageType: computed(
    'hasErrorMessages',
    'hasInfoMessages',
    'hasWarnMessages',
    'hasSuccessMessages',
    function computedCurrentMessageType() {
      if (this.get('hasErrorMessages')) {
        return 'error';
      }

      if (this.get('hasInfoMessages')) {
        return 'info';
      }

      if (this.get('hasWarnMessages')) {
        return 'warn';
      }

      if (this.get('hasSuccessMessages')) {
        return 'success';
      }

      return null;
    },
  ),

  actions: {
    close(type) {
      new Main().apiClearMessages(type);
    },
  },
});
