import MerchandiseCategory from './merchandise_category';
import PointAllocation from './point_allocation';

export default class MerchandiseSegment extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    /** @type {string} */
    this.name = o.name === undefined ? null : o.name;

    /** @type {string} */
    this.description = o.description === undefined ? null : o.description;

    /** @type {number} */
    this.sellPrice = o.sellPrice === undefined ? null : o.sellPrice;

    /** @type {number} */
    this.listPrice = o.listPrice === undefined ? null : o.listPrice;

    /** @type {string} */
    this.imageUrl = o.imageUrl === undefined ? null : o.imageUrl;

    /** @type {string} */
    this.sku = o.sku === undefined ? null : o.sku;

    /** @type {string} */
    this.sizeOfItem = o.sizeOfItem === undefined ? null : o.sizeOfItem;

    /** @type {string} */
    this.headline = o.headline === undefined ? null : o.headline;

    /** @type {string} */
    this.currency = o.currency === undefined ? null : o.currency;

    /** @type {string} */
    this.merchandiseCode = o.merchandiseCode === undefined ? null : o.merchandiseCode;

    /** @type {string} */
    this.currencySymbol = o.currencySymbol === undefined ? null : o.currencySymbol;

    /** @type {number} */
    this.defaultSortId = o.defaultSortId === undefined ? null : o.defaultSortId;

    /** @type {number} */
    this.quantity = o.quantity === undefined ? null : o.quantity;

    // ----- deserialized properties -----

    this.deserializeProperty(o, 'merchandiseCategory', MerchandiseCategory);

    this.deserializeArray(o, 'pointAllocations', PointAllocation);
  }

  // todo: revisit this
  // When merchandise segments are added to a cart, they need to be cloned
  // Keep this array in sync with the properties that need to be copied over
  // to App.MerchandiseSegmentInCart.
  getCloneableProperties() {
    // todo: see if this works because we don't want to depend on Ember
    return this.serialize();
    // return this.getProperties([
    //     'name',
    //     'description',
    //     'sellPrice',
    //     'listPrice',
    //     'imageUrl',
    //     'sku',
    //     'sizeOfItem',
    //     'headline',
    //     'currency',
    //     'merchandiseCode',
    //     'currencySymbol'
    // ]);
  }
}
