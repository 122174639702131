export default class MerchandiseShoppingCartState extends LIQUID.models.Base {
  constructor(o = {}) {
    super(o);

    // ----- properties ---

    // todo: what is this?
    /** @type {?} */
    this.loyaltyProfile = o.loyaltyProfile === undefined ? null : o.loyaltyProfile;
  }
}
