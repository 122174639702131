import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
  tagName: 'input',
  type: 'radio',

  attributeBindings: [
    'name',
    'type',
    'value',
    'checked:checked',
    'disabled:disabled',
  ],

  didInsertElement() {
    $(this.element).on('change', () => {
      this.set('selectedValue', $(this).val());
    });

    this.setCheckbox($(this).val());
  },

  willDestroyElement() {
    $(this).off('change');
  },

  selectionObserver: observer('selectedValue', function selectionObserver() {
    const value = $(this).val();
    this.setCheckbox(value);

    if (value === this.get('selectedValue')) {
      this.onClick(value);
    }
  }),

  setCheckbox(value) {
    const selection = this.get('selectedValue');
    const checked = value === selection;
    $(this.element).attr('checked', checked).prop('checked', checked);
  },
});
