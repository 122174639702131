import Component from '@ember/component';
import { bind } from '@ember/runloop';
import $ from 'jquery';
import config from '../../../config/environment';

export default Component.extend({
  // PassedIn
  cart: null,
  numItemsDisplayed: null,
  sortedSegments: null,

  // When we insert this view into the dom bind the window's
  // scroll view to a function that executes our didScroll
  // function.
  didInsertElement() {
    $(window).bind('scroll', () => {
      bind(this, this.didScroll);
    });
  },

  willDestroyElement() {
    $(window).unbind('scroll');
  },

  didScroll() {
    if (this.isScrolledToBottom()) {
      this.loadMoreItems();
    }
  },

  isScrolledToBottom() {
    const distanceToTop = $(document).height() - $(window).height();

    return (
      $(document).scrollTop() === distanceToTop
            || $(document).scrollTop() === distanceToTop - 1
    );
  },

  loadMoreItems() {
    const numItemsDisplayed = this.get('numItemsDisplayed');
    const initNumItemsToShow = config.DEFAULT_NUM_ITEMS_TO_SHOW;
    const merchItems = $('.merchandise-segment');
    if (!numItemsDisplayed) {
      this.set('numItemsDisplayed', initNumItemsToShow);
    }
    const endIndex = numItemsDisplayed + initNumItemsToShow;
    this.showItems(numItemsDisplayed, endIndex);
    this.set('numItemsDisplayed', endIndex);

    if (numItemsDisplayed >= merchItems.length) {
      $('footer').show();
      $('.shopping-cart-container.bottom').show();
    }
  },

  showItems(indexStart, indexEnd) {
    // use this as the default behavior
    const merchItems = $('.merchandise-segment');
    if (indexStart && indexEnd) {
      for (let i = indexStart; i < indexEnd; i++) {
        $(merchItems[i]).show();
      }
    }
  },

  actions: {
    setNumItemsDisplayed(numItemsDisplayed) {
      this.set('numItemsDisplayed', numItemsDisplayed);
    },
  },
});
