/**
 * @param {Object} obj
 * @param {string} path
 */
export function getObjectByPath(obj, path) {
  if (!obj || !(obj instanceof Object)) {
    throw new Error('liquid.utils:getObjectByPath(): no object');
  }

  if (!path || typeof path !== 'string') {
    throw new Error('liquid.utils:getObjectByPath(): no path');
  }

  let o = obj;
  const parts = path.split('.');
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    o = o[part];
    if (o === undefined) {
      return undefined;
    }
  }
  return o;
}

/**
 * @param {Object} before
 * @param {Object} after
 * @param {Object} afterClass
 */
export function createChangeList(before, after, afterClass) {
  const changeSet = DeepDiff(before, after);

  if (changeSet) {
    const ret = changeSet.map((deepDiffItem) => {
      let value;
      switch (deepDiffItem.kind) {
        case 'E': {
          value = deepDiffItem.path
            ? getObjectByPath(
              afterClass,
              deepDiffItem.path.join('.'),
            )
            : afterClass;
          break;
        }
        case 'N': {
          value = deepDiffItem.path
            ? getObjectByPath(
              afterClass,
              deepDiffItem.path.join('.'),
            )
            : afterClass;
          break;
        }
        case 'A': {
          const array = deepDiffItem.path
            ? getObjectByPath(
              afterClass,
              deepDiffItem.path.join('.'),
            )
            : afterClass;

          switch (deepDiffItem.item.kind) {
            case 'N':
              value = array[deepDiffItem.index];
              break;

            case 'D':
              break;
            default:
              break;
          }
          break;
        }
        case 'D': {
          break;
        }
        default:
          break;
      }
      return {
        kind: deepDiffItem.kind,
        path: deepDiffItem.path,
        index: deepDiffItem.index,
        item: deepDiffItem.item,
        lhs: deepDiffItem.lhs,
        rhs: deepDiffItem.rhs,
        value,
      };
    });
    // eslint-disable-next-line no-console
    if (console.table && LIQUID.DEBUG_LEVEL === 1) {
      // eslint-disable-next-line no-console
      console.table(
        changeSet.map((deepDiffItem) => {
          const rhs = deepDiffItem.item
            ? deepDiffItem.item.rhs
            : deepDiffItem.rhs;
          return {
            kind: deepDiffItem.item
              ? `${deepDiffItem.kind
              }[${
                deepDiffItem.index
              }] ${
                deepDiffItem.item.kind}`
              : deepDiffItem.kind,
            path: deepDiffItem.path.join('.'),
            before: deepDiffItem.lhs,
            after:
                            typeof rhs === 'object' ? JSON.stringify(rhs) : rhs,
          };
        }),
      );
    }
    return ret;
  }
  // nothing changed
  return [];
}
