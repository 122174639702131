import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from './resolver';
import config from './config/environment';
import Translator from './libs/translator';

// todo: figure out what is needed and best placed in config ...
const App = Application.extend({
  modulePrefix: config.modulePrefix, // needed?
  podModulePrefix: config.podModulePrefix, // needed?
  rootElement: '#merchandise-shopping-content',
  Resolver,
});

loadInitializers(App, config.modulePrefix);

App.configurations = {};
App.translator = new Translator({});
App.messages = [];
App.cookies = window.Cookies;

export default App;
