import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // passedIn
  shoppingCart: null,

  totalSellPrice: computed(
    'shoppingCart.merchandiseSegments.@each.quantity',
    function computedTotalSellPrice() {
      return this.get('shoppingCart.merchandiseSegments').reduce(
        (totalSellPrice, item) => totalSellPrice + item.sellPrice * item.quantity,
        0,
      );
    },
  ),

  totalItems: computed(
    'shoppingCart.merchandiseSegments.@each.quantity',
    function computedTotalItems() {
      return this.get('shoppingCart.merchandiseSegments').reduce(
        (totalItemCount, item) => totalItemCount + item.quantity,
        0,
      );
    },
  ),
});
