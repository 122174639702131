import Route from '@ember/routing/route';
import Main from '../../liquid/actions/main';

export default Route.extend({
  model() {
    // TODO: on later refactor we should get rif of accessing Spinner service via this.controllerFor('merchandiseCategories')
    // TODO:  We don't really need a service for this, it should be a controller.
    const spinner = this.controllerFor('merchandiseCategories').get(
      'spinner',
    );
    spinner.show('Please wait until we load our product list.');

    return new Main().apiGetCategories().finally(() => {
      spinner.hide();
    });
  },
});
