import Mixin from '@ember/object/mixin';

/**
 * @param {string} prop : path to property
 * @param {Array.<object>} change
 */
function _getPathForChange(prop, change) {
  return change.path ? `${prop}.${change.path.join('.')}` : prop;
}

export default Mixin.create({
  /**
     * @param {string} prop : path to property
     * @param {Array.<object>} changeList
     */
  applyChangeList(prop, changeList) {
    this._applyChangeList_NonArray(prop, changeList);
    this._applyChangeList_Array(prop, changeList);
  },

  /**
     * @param {string} prop : path to property
     * @param {Array.<object>} changeList
     */
  _applyChangeList_NonArray(prop, changeList) {
    const nonArrayChangeList = changeList.filter((change) => change.kind !== 'A');

    nonArrayChangeList.forEach((change) => {
      const path = _getPathForChange(prop, change);
      // console.log('PATH: ' + path);

      switch (change.kind) {
        case 'E': {
          const lastPathValue = change.path[change.path.length - 1];
          if (typeof lastPathValue === 'number') {
            // a property change at an existing index of an array
            const arrayPath = change.path
              ? `${prop}.${change.path.slice(0, -1).join('.')}`
              : prop;
            this.get(arrayPath).replace(lastPathValue, 1, [
              change.value,
            ]);
          } else {
            this.set(path, change.value);
          }
          break;
        }

        case 'N': {
          this.set(path, change.value);
          break;
        }
        case 'D': {
          // if you delete, not sure if it gets ember change notified, thus setting to undefined
          this.set(path, undefined);
          break;
        }
        default:
          break;
      }
    });
  },

  /**
     * @param {string} prop : path to property
     * @param {Array.<object>} changeList
     */
  _applyChangeList_Array(prop, changeList) {
    const self = this;

    const arrayChangeList = changeList.filter((change) => change.kind === 'A');

    // new items
    const newArrayItemChangeList = arrayChangeList.filter((change) => change.item.kind === 'N');

    newArrayItemChangeList.forEach((change) => {
      const array = self.get(_getPathForChange(prop, change));
      // The remove is a safeguard to protect against duplicate entries being inserted into the array
      // in the case where there are multiple subscribers (ex. multiple controllers) to a store event,
      // causing this routine to get called multiple times against the SAME array object.
      if (array[change.index] !== undefined) {
        array.removeAt(change.index);
      }
      array.insertAt(change.index, change.value);
    });

    // delete items in reverse
    const deleteArrayItemChangeList = arrayChangeList
      .filter((change) => change.item.kind === 'D')
      .reverse();

    deleteArrayItemChangeList.forEach((change) => {
      const array = self.get(_getPathForChange(prop, change));
      if (array[change.index]) {
        array.removeAt(change.index);
      }
    });
  },
});
