import TextField from '@ember/component/text-field';
import { observer } from '@ember/object';

export default TextField.extend({
  classNameBindings: 'invalid:invalid:valid',

  willDestroyElement() {
    this._destroyTooltip();
  },

  showError: observer('invalid', function showErrorObserver() {
    const tooltip = {
      title: this.get('invalid'),
      placement: 'right',
      trigger: 'manual',
    };

    if (this.get('invalid')) {
      $(this.element).tooltip(tooltip).tooltip('show');
    } else {
      this._destroyTooltip();
    }
  }),

  _destroyTooltip() {
    if ($(this.element).data('tooltip')) {
      $(this.element).tooltip('destroy');
    }
  },

  focusIn() {
    if (this.get('invalid')) {
      $(this.element).tooltip('show');
    }
  },

  focusOut() {
    $(this.element).tooltip('hide');
  },
});
